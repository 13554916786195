/* eslint react/prop-types: 0 */
import './App.css';
import Authenticator from './ui/auth/Authenticator';
import { useAuthentication } from './data/auth/transition';
import logo from './assets/logo.png';
import empty from './empty.png';
import Dropzone from 'react-dropzone-uploader';

const Spin = () => (
  <svg className="mx-auto animate-spin" height="40" width="40" viewBox="0 0 40 40">
    <circle cx="20" cy="20" r="10" fill="white" stroke="gray" strokeWidth="1" />
    <path d="m 20 20 l -5 -20 l 10 0 z" stroke="white" fill="white" strokeWidth="1" />
  </svg>
);

const Fail = () => <div className="text-xs px-3 py-1.5 font-semibold text-white bg-red-500 rounded-full">Failed</div>;

const Done = () => <div className="text-xs px-3 py-1.5 font-semibold text-white bg-green-500 rounded-full">Done</div>;

const Loading = () => (
  <div className="h-screen flex">
    <div className="m-auto">
      <Spin />
    </div>
  </div>
);

const Form = ({ ...props }) => (
  <div {...props.dropzoneProps}>
    <div className="bg-grayscale-8 h-screen w-screen text-sm font-sans">
      <div id="file-input" className="hidden">
        {props.input}
      </div>
      <div className="max-w-7xl mx-auto py-12 sm:px-6 lg:px-8">
        <div className="max-w-2xl mx-auto">
          <div className="w-36 mb-6 ml-5">
            <img src={logo} alt="Logo" />
          </div>
          <div className="bg-white shadow-lg overflow-hidden sm:rounded-lg">
            <div className="p-12 space-y-5">
              <div className="space-y-6">
                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={() => document.querySelector('#file-input input').click()}
                    className="rounded focus:outline-none font-medium shadow hover:shadow-md active:opacity-50 text-indigo-600 px-5 py-1.5"
                  >
                    + Add a file
                  </button>
                </div>
                <div className="w-full">
                  {props.previews.length > 0 ? (
                    props.previews
                  ) : (
                    <img src={empty} className="h-52 mx-auto" alt="Empty state" />
                  )}
                </div>
                <div className="text-grayscale-100 p-2">
                  To securely upload files, click the button above, or drag and drop files onto this page.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const Preview = ({ ...props }) => {
  // eslint-disable-next-line no-nested-ternary
  const badge = props.meta.status.match(/(error|exception)/) ? (
    <Fail />
  ) : props.meta.status === 'done' ? (
    <Done />
  ) : (
    <Spin />
  );
  return (
    <div className="flex p-3 mt-2 border rounded border-grayscale-16 justify-between">
      <span className="font-semibold my-auto">{props.meta.name}</span>
      <span>{badge}</span>
    </div>
  );
};

function App() {
  const { getAccessToken } = useAuthentication();

  const getUploadParams = async () => {
    const response = await fetch('https://hub.getbenepass.com/drop', {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
    });
    const { fields, url } = await response.json();
    return { fields, url, method: 'POST' };
  };

  return (
    <Authenticator loading={Loading}>
      <Dropzone LayoutComponent={Form} PreviewComponent={Preview} getUploadParams={getUploadParams} />
    </Authenticator>
  );
}

export default App;
